import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import { setInitUrl } from "../../store/actions/authAction";

import ProtectedRoute from "./ProtectedRoute";
import utils from "../../util/utils";
import ChildLoginWithToken from "../../scenes/Anonymous/ChildLoginWithToken";
import ParentLoginWithToken from "../../scenes/Anonymous/ParentLoginWithToken";
import { useIsLoggedIn, usePostSessionAnalytics, useUpdateCurrentUserRole } from "../../util/hooks";
import asyncComponent from "util/asyncComponent";
import { matchingRoutes } from "./router.config";

const Router = (props) => {
    const updateCurrentUserRole = useUpdateCurrentUserRole();
    const isLoggedIn = useIsLoggedIn();

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        updateCurrentUserRole();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location]);

    usePostSessionAnalytics();

    const AnonymousUserLayout = utils.getRoute("/user").component;
    const AuthenticatedUserLayout = utils.getRoute("/").component;

    return (
        <Switch>
            <Route path="/child-login-with-token/:token" component={ChildLoginWithToken} exact />
            <Route path="/parent-login-with-token/:token" component={ParentLoginWithToken} exact />
            <Route
                path="/calendly"
                component={asyncComponent(() =>
                    import("../../scenes/Anonymous/MarketingLanding/Calendly")
                )}
                exact
            />

            <Route
                path="/user"
                render={(props) => {
                    return <AnonymousUserLayout {...props} />;
                }}
            />
            {!isLoggedIn &&
                matchingRoutes.map((matchingRoute, index) => (
                    <Redirect
                        key={index}
                        from={matchingRoute.authenticatedPath}
                        to={matchingRoute.anonymousPath}
                    />
                ))}

            <ProtectedRoute
                path="/"
                render={(props) => {
                    return <AuthenticatedUserLayout {...props} exact />;
                }}
                permission={true}
            />
        </Switch>
    );
};

export default memo(Router);
