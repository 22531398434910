import AppConsts from "../lib/appconst";
import axios from "axios";
import logger from "./logService";
import { toast } from "react-toastify";
import utils from "../util/utils";
import Cookies from "js-cookie";

import tokenAuthService from "./tokenAuth/tokenAuthService";
import parseHttpError from "./util/parseHttpError";

const qs = require("qs");

export const updateAuthToken = () => {
    const refreshToken = tokenAuthService.getRefreshToken();

    return axios
        .post(AppConsts.remoteServiceBaseUrl + "/api/auth/refresh-token-global", {
            refresh_token: refreshToken,
        })
        .then((result) => {
            if (result.status === 200) {
                const expdate = utils.getExpiryDate();

                const newtoken = result.headers["x-auth-token"];
                const newrfshtoken = result.headers["x-auth-refresh-token"];

                Cookies.set(process.env.REACT_APP_ACCESS_TOKEN_VARIABLE, newtoken, {
                    expires: expdate,
                });

                Cookies.set(process.env.REACT_APP_REFRESH_TOKEN_VARIABLE, newrfshtoken, {
                    expires: expdate,
                });

                return "Bearer " + newtoken;
            }

            return null;
        })
        .catch(function (err) {
            tokenAuthService.logout();
            window.location.href = "/user";
            return null;
        });
};

const http = axios.create({
    baseURL: AppConsts.remoteServiceBaseUrl,
    timeout: 120000,
    paramsSerializer: function (params) {
        return qs.stringify(params, {
            encode: false,
        });
    },
});

http.interceptors.request.use(
    function (config) {
        var token = tokenAuthService.getAccessToken();
        if (!!token) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

class HttpError extends Error {
    constructor(response) {
        super(response.statusText);
        this.response = response;
    }
}

http.interceptors.response.use(
    (response) => {
        if (response && response.data === "Token Expired") {
            const originalRequest = response.config;

            originalRequest._retry = true;

            return updateAuthToken().then((newToken) => {
                originalRequest.headers["Authorization"] = newToken;
                return http(originalRequest);
            });
        } else return response;
    },
    (error) => {
        const expectedError =
            error.response && error.response.status >= 400 && error.response.status < 500;

        //both error must be log
        logger.log(`Logging the ${expectedError ? "expected api" : "unknown"} error`, error);

        return Promise.reject(
            expectedError ? new HttpError(error.response) : parseHttpError(error)
        );
    }
);

export default http;
