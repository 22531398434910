import { createGlobalStyle } from "styled-components";
import { createTextStroke } from "util/utils";

const GlobalCssBaselineStyles = createGlobalStyle`
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    /* list-style: none; */
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


h1 {
        font-family: Fredoka;
        font-size: 2.2rem;
    }

    h1.highlighted{
        color: var(--button-primary);
        ${() => createTextStroke(2, "var(--text-color)")}
    }

    h2 {
        font-size: 1.5rem;
    }

    h3 {
        font-size: 1.2rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5, h6 {
        font-weight: 800;
    }

    body {
        font-family: "Nunito";
        margin: 0;
        font-size: 16px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        color: var(--text-color);
        background-color: var(--bg-color);
        overflow-x: hidden;

        > iframe {
            display: none;
        }
    }

    ::selection {
        background-color: var(--button-primary);
        color: var(--text-color);
    }

    ::-moz-selection {
        background-color: var(--button-primary);
        color: var(--text-color);
    }

    input,
    textarea {
        color: var(--text-color);
        caret-color: var(--text-color);
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type="number"] {
        -moz-appearance: textfield;
    }

`;

const GlobalCssBaseline = () => {
    return <GlobalCssBaselineStyles />;
};

export default GlobalCssBaseline;
