import { titleCase } from "../../util/stringUtils";
import http from "../httpService";

class AIService {
    async getAIServerWarmup() {
        try {
            const result = await http.get("/api/ai/server-warmup").then((res) => res.data);
            console.log(result);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async getAIPreview(topic, lessonSlug) {
        try {
            const result = await http
                .post("/api/ai/check-topic", { topic, lessonSlug })
                .then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async generateLesson(topic, lessonSlug, kidId) {
        try {
            const result = await http
                .post(
                    "/api/ai/generate-lesson",
                    { topic, lessonSlug, kidId }
                    // {
                    //     responseType: "arraybuffer", // To get the data as a raw buffer
                    // }
                )
                .then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async generateLessonPdf(aiModuleId) {
        try {
            const result = await http
                .get(`/api/ai/generate-lesson/${aiModuleId}/pdf`, {
                    responseType: "arraybuffer", // To get the data as a raw buffer
                })
                .then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    /**
     * Fetches all lessons for a given kid. The function makes an HTTP POST request to retrieve the lessons.
     *
     * @typedef {import("./types").Lesson} Lesson
     * @param {Object} params - The parameters for fetching lessons.
     * @param {string} params.kidId - The ID of the kid for whom to fetch lessons.
     * @param {string | undefined} [params.subjectId] - The ID of the subject to filter lessons.
     * @param {string | undefined} [params.slug] - The slug for filtering lessons to a specific one.
     * @param {string | undefined} [params.subjectName] - The name of the subject to filter lessons.
     * @returns {Promise<Lesson[]>} A promise that resolves with an array of `Lesson` objects or an error object if the request fails.
     */

    async getAllKidLessons({ kidId, subjectId, slug, subjectName }) {
        let body = { kidId };

        if (subjectId) {
            body.subjectId = subjectId;
        }

        if (slug) {
            body.slug = slug;
        }

        if (subjectName) {
            body.subjectName = subjectName;
        }

        try {
            const result = await http.post("/api/ai/all-lessons", body).then((res) => res.data);
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    async checkLessonTheme({ topic, kidId }) {
        try {
            const result = await http
                .post("/api/ai/check-lesson-topic", { topic, kidId })
                .then((res) => res.data)
                .then((data) => ({ ...data, isThemeValid: data.is_valid }));
            return result;
        } catch (ex) {
            return { apierror: ex.response.data };
        }
    }

    // export const getKidLessonsByAgeForAiGenerator = async (age) => {
    //     const { data: result } = await axios.post("/api/parents/lessons-by-age", { age });
    //     return result;
    // };
}

const KID_NAME_REGEX = /{{KID_NAME}}/g;

/**
 *
 * @param {LessonComponent[]} components
 * @param {string} kidName
 * @returns {LessonComponent[]}
 */
export const parseComponents = (components, kidName) => {
    return components.map(({ text, heading, ...component }) => ({
        ...component,
        text: text?.replace(KID_NAME_REGEX, titleCase(kidName)),
        heading: heading?.replace(KID_NAME_REGEX, titleCase(kidName)),
        options: component.options?.map((option) =>
            option.replace(KID_NAME_REGEX, titleCase(kidName))
        ),
        correct_ans: component.correct_ans?.replace(KID_NAME_REGEX, titleCase(kidName)),
    }));
};

/**
 * @typedef { import('./types').LessonComponent } LessonComponent
 */

/**
 * Represents the structure of the function's output result.
 * @typedef {Object} LessonComponentsResult
 * @property {LessonComponent[]} components - Array of lesson components.
 * @property {string} lessonId - The identifier of the lesson.
 */

/**
 * Asynchronously retrieves and processes lesson components based on a reference and kid's name.
 *
 * @param {{ ref: string, kidName: string
 *  replaceKidName?: boolean
 *  }} param0 - Object containing the reference to the lesson and the kid's name to replace.
 * @returns {Promise<LessonComponentsResult>} A promise that resolves to the lesson components result.
 */
export const getCurrentKidLessonComponentsFromRef = async ({
    ref,
    kidName,
    replaceKidName = true,
}) => {
    const { data } = await http.get("/api/ai/get-kid-ai-lesson/" + ref);

    const components = replaceKidName ? parseComponents(data.components, kidName) : data.components;

    return {
        ...data,
        components,
    };
};

const aiServiceInstance = new AIService();
export default aiServiceInstance;
