require("dotenv").config();

const config = {
    environment: process.env.REACT_APP_ENVIRONMENT.toLowerCase(),
    backendUrl: process.env.REACT_APP_BACKEND_URL,
    backendAiUrl: process.env.REACT_APP_BACKEND_AI_URL || "https://financy-ai-audio.vercel.app",
    tutorAiDomain:
        process.env.REACT_APP_TUTOR_AI_DOMAIN || "ai-tutor-staging-48b07759b55a.herokuapp.com",
    frontendUrl: process.env.REACT_APP_FRONTEND_URL,
    fbPixel: process.env.REACT_APP_FACEBOOK_PIXEL_ID,
    gameUrl: process.env.REACT_APP_GAME_URL || "/game/",

    localStorageVariables: {
        email: "user-email-var-1",
        password: "user-password-var-1",
    },
};

console.log(config);

export default config;

export const localStorageFormKeys = {
    onboarding: {
        select_subject:
            process.env.REACT_APP_ONBOARDING_SELECT_SUBJECT_FORM_KEY ||
            "onboarding-select-subject_form",

        edit_curriculum: {
            selected_lessons:
                process.env.REACT_APP_ONBOARDING_EDIT_CURRICULUM_SELECTED_LESSONS_FORM_KEY ||
                "onboarding-edit-curriculum_selected-lessons",
            is_completed:
                process.env.REACT_APP_ONBOARDING_EDIT_CURRICULUM_IS_COMPLETED_FORM_KEY ||
                "onboarding-edit-curriculum_is-completed",
        },
        add_themes: {
            selected_themes:
                process.env.REACT_APP_ONBOARDING_ADD_THEMES_SELECTED_THEMES_FORM_KEY ||
                "onboarding-add-themes_selected-themes",
            is_skipped:
                process.env.REACT_APP_ONBOARDING_ADD_THEMES_IS_SKIPPED_FORM_KEY ||
                "onboarding-add-themes_is-skipped",
            has_filled_with_empty_theme:
                process.env.REACT_APP_ONBOARDING_ADD_THEMES_HAS_FILLED_WITH_EMPTY_THEME_FORM_KEY ||
                "onboarding-add-themes_has-filled-with-empty-theme",
        },
        add_child: {
            name:
                process.env.REACT_APP_ONBOARDING_ADD_CHILD_NAME_FORM_KEY ||
                "onboarding-add-child_name",
            age:
                process.env.REACT_APP_ONBOARDING_ADD_CHILD_AGE_FORM_KEY ||
                "onboarding-add-child_age",
        },
        login_dialog: {
            current_form:
                process.env.REACT_APP_ONBOARDING_LOGIN_DIALOG_CURRENT_FORM_FORM_KEY ||
                "onboarding-login-dialog_current-form",
            user_email:
                process.env.REACT_APP_ONBOARDING_LOGIN_DIALOG_USER_EMAIL_FORM_KEY ||
                "onboarding-login-dialog_user-email",
        },
        stored_onboarding_form:
            process.env.REACT_APP_ONBOARDING_STORED_FORM_KEY || "onboarding-stored-form",
    },
};

export const isProd = () => config.environment === "production";
