import { AxiosError } from "axios";

const parseHttpError = (error: AxiosError): string => {
    if (error?.response) {
        return error?.response?.data;
    } else if (error?.message) {
        return error?.message;
    }

    return JSON.stringify(error) || "Network error.";
};

export default parseHttpError;
