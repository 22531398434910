import { useEffect } from "react";
import tokenAuthService from "../../../services/tokenAuth/tokenAuthService";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useUpdateCurrentUserRole } from "../../../util/hooks";
import { sendAmplitudeEvent } from "../../../util/amplitude";

export type OtpData = {
    enabled: true;
    email: string;
};

export const useParentLoginWithToken = () => {
    const updateCurrentUserRole = useUpdateCurrentUserRole();
    const history = useHistory();

    const parentLoginWithToken = ({
        token,
        successRedirectPath,
        successRedirectState = null,
        isReturningUser = false,
        otpData = undefined,
    }: {
        token: string;
        successRedirectPath: string;
        isReturningUser?: boolean;
        successRedirectState?: any;
        otpData?: OtpData;
    }) =>
        new Promise<void>((resolve, reject) => {
            tokenAuthService.logout();

            if (isReturningUser) {
                localStorage.setItem("IS_RETURNING_USER", JSON.stringify(true));
            }

            tokenAuthService
                .checkLoginToken(token, otpData)
                .then(() => {
                    try {
                        sendAmplitudeEvent("v4 - 2c - Parent Login With Token");
                    } catch (e) {
                        console.error(e);
                    }
                    if (successRedirectState) {
                        history.replace(successRedirectPath, successRedirectState);
                    } else {
                        history.replace(successRedirectPath);
                    }
                })
                .finally(updateCurrentUserRole)
                .catch((e) => {
                    console.error("Parent login with token error", e);
                    reject(e);
                })
                .then(resolve);
        });

    return parentLoginWithToken;
};

const ParentLoginWithToken = () => {
    const { token } = useParams<{ token: string }>();
    const { search } = useLocation();
    const parentLoginWithToken = useParentLoginWithToken();
    const history = useHistory();

    useEffect(() => {
        if (!token) return;
        const redirectPath = new URLSearchParams(search).get("to") || "/";
        const isReturningUser = new URLSearchParams(search).get("ru") === "true";

        parentLoginWithToken({ token, successRedirectPath: redirectPath, isReturningUser }).catch(
            () => {
                history.replace(redirectPath);
            }
        );
    }, [token, search, parentLoginWithToken, history]);

    return <></>;
};

export default ParentLoginWithToken;
