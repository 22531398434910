import Global from "./global";
import "./colorSchema.css";
import "./nprogress.css"
import GlobalCssBaseline from "./GlobalCssBaseline";

const Theme = () => {
    return (
        <>
            <Global />
            <GlobalCssBaseline />
        </>
    );
};

export default Theme;
