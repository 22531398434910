import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import logger from "./services/logService";
import { createBrowserHistory } from "history";
import { initAmplitude } from "./util/amplitude";
import { AudioProvider } from "./util/AudioContext";
import { ChildrenProvider } from "./util/childrenContext";
import Theme from "theme";

const store = configureStore();
logger.init();
initAmplitude();

const history = createBrowserHistory();

class Root extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <React.StrictMode>
                        <ChildrenProvider>
                            <AudioProvider>
                                <App />
                                <Theme />
                            </AudioProvider>
                        </ChildrenProvider>
                    </React.StrictMode>
                </Router>
            </Provider>
        );
    }
}

ReactDOM.render(<Root />, document.getElementById("root"));
