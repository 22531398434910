import { createGlobalStyle } from "styled-components";

const styled = { global: createGlobalStyle };

const GlobalStyles = styled.global`
    .large-outlined-text {
        font-size: 40px;
    }
    .small-outlined-text {
        font-size: 24px;
    }

    .small-outlined-text,
    .large-outlined-text {
        letter-spacing: 0.03em;
        color: rgba(22, 24, 30, 0.7);
        paint-order: stroke fill;
    }

    .wrapperClassName {
        border: 1px solid grey;
        border-radius: 3px;
        max-width: 600px;
    }

    .editorClassName {
        padding: 10px;
    }

    button {
        border: none;
        padding: 0;
        font-weight: 800;
        user-select: none;
        font-family: "Nunito";

        &:enabled {
            cursor: pointer;

            &.brightness-highlight {
                @media (hover: hover) {
                    &:hover {
                        filter: brightness(120%);
                    }
                }

                &:active {
                    filter: brightness(160%);
                }
            }
        }

        img {
            user-select: none;
            pointer-events: none;
        }
    }

    #preloaded-audio-container {
        position: absolute;
        top: 0;
        left: 0;
        visibility: hidden;
        height: 0;
        width: 0;
        z-index: -1000;
    }

    .primary-btn,
    .secondary-btn,
    .transparent-btn {
        padding: 4px 20px;
        border-radius: 25px;
        font-size: 16px;
        margin: 5px;
        font-family: "Nunito";
        font-weight: 600;
        display: flex;
        align-items: center;
        text-decoration: none;
        text-align: center;

        &:not(:disabled) {
            cursor: pointer;
        }

        &:active:not(:disabled) {
            transform: translateY(2px);
        }
    }

    .primary-btn {
        --primary-btn-border: 2px solid var(--text-color);
        background-color: var(--button-primary);
        color: var(--text-color);

        &:not(.no-shadow) {
            box-shadow: 2px 2px 0 0 var(--text-color);
        }

        &:not(.no-border) {
            border: var(--primary-btn-border);
        }

        @media (hover: hover) {
            &:hover:not(:disabled) {
                background-color: var(--button-primary-hover);

                &.no-border {
                    outline: var(--primary-btn-border);
                }
            }
        }
    }

    .secondary-btn {
        --secondary-btn-text-color: var(--white);
        --button-secondary-background-color: var(--button-secondary);

        --button-secondary-hover-text-color: var(--button-secondary);
        --button-secondary-hover-background-color: var(--button-secondary-hover);

        background-color: var(--button-secondary-background-color);
        color: var(--secondary-btn-text-color);

        @media (hover: hover) {
            &:hover:not(:disabled) {
                --secondary-btn-text-color: var(--button-secondary-hover-text-color);
                background-color: var(--button-secondary-hover-background-color);
            }
        }

        &:disabled {
            background-color: var(--disabled-bg-color);
            color: var(--disabled-text-color);
        }
    }

    .secondary-btn.inverted {
        --secondary-btn-text-color: var(--button-secondary);
        --button-secondary-background-color: var(--button-secondary-hover);

        --button-secondary-hover-text-color: var(--white);
        --button-secondary-hover-background-color: var(--button-secondary);
    }

    .transparent-btn {
        background-color: transparent;
        color: var(--text-color);
        border: 2px solid var(--text-color);

        @media (hover: hover) {
            &:hover:not(:disabled) {
                background-color: var(--button-primary-hover);
            }
        }
    }

    .text-btn {
        --text-btn-color: var(--text-color);
        --text-btn-color-hover: var(--button-secondary);
        --text-btn-disabled-color: var(--btn-disabled);

        display: inline-block;
        background-color: transparent;
        color: var(--text-btn-color);
        padding: 0;
        border-radius: 25px;
        font-size: 16px;
        cursor: pointer;
        border: none;
        margin: 0;
        font-family: "Nunito";
        font-weight: 600;

        &:not(:hover) {
            text-decoration: none;
        }

        &:disabled {
            color: var(--text-btn-disabled-color);
            cursor: default;
        }

        @media (hover: hover) {
            &:hover:not(:disabled) {
                color: var(--text-btn-color-hover);
                text-decoration: underline;
                text-decoration-color: var(--text-btn-color-hover);
            }
        }
    }

    .link-btn {
        background-color: transparent;
        border-bottom: 1px solid var(--white);
        color: var(--white);
        font-family: "Nunito";
    }
`;

const Global = () => {
    return <GlobalStyles />;
};

export default Global;
