import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import utilsInstance, { hasFinishedOnboarding, isInReactOnboardingStep } from "./utils";
import kidServiceInstance from "../services/kidServices/kidServices";
import config from "../config/config";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import sessionServiceInstance from "../services/session/sessionService";
import {
    setAuthUserEmail,
    setAuthUserFirstName,
    setAuthUserRole,
} from "../store/actions/authAction";
import {
    postUserAcquisition,
    postUserSessionAnalytic,
} from "../services/analyticServices/analyticServices";
import { useChildren } from "./childrenContext";
import isEqual from "lodash/isEqual";
import aiServiceInstance from "../services/ai/aiService";
import { useListenForMultipleAiJobStatus } from "../firebase/hooks";
import { useCallback } from "react";
import { updateAuthToken } from "../services/httpService";
import axios from "axios";
import { toast } from "react-toastify";
import { useAudio, useAudioToggle } from "./AudioContext";
import { ADMIN, USER } from "constants/LookupConst";
import useSessionStorage from "./tsHooks/useSessionStorage";

/**
 * Custom hook to memoize a value deeply. It uses a deep comparison to determine if the value has changed.
 * If the value has changed, it updates the memoized value; otherwise, it retains the previous memoized value.
 *
 * @template T The type of the value to be memoized.
 * @param {T} value The value to be memoized. Can be of any type that needs deep comparison.
 * @returns {T} The memoized value.
 */
function useDeepCompareMemoize(value) {
    const ref = useRef();

    if (!isEqual(value, ref.current)) {
        ref.current = value;
    }

    return ref.current;
}

/**
 * Custom hook that memoizes the callback based on the deep comparison of dependencies.
 * It's similar to `useMemo`, but uses `useDeepCompareMemoize` for dependency comparison,
 * which is useful for dependencies that are objects or arrays that might change in reference but not in content.
 *
 * @template T The type of the return value of the callback function.
 * @param {() => T} callback The callback function that returns the memoized value.
 * This is the value that will be memoized and returned.
 * @param {Array} dependencies An array of dependencies to determine when to recalculate the memoized value.
 * The deep comparison is used here.
 * @returns {T} The memoized value returned by `callback`.
 */
export function useDeepCompareMemo(callback, dependencies) {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return useMemo(callback, useDeepCompareMemoize(dependencies));
}

export const useFilterArray = ({ inputText, filter_by, data }) => {
    const [filteredData, setFilteredData] = useState(() => filter_by(inputText, data));

    useEffect(() => {
        setFilteredData(filter_by(inputText, data));
    }, [data, filter_by, inputText]);

    return filteredData;
};

const useAsyncBase = (
    { loading, setLoading, firstLoad, setFirstLoad, error, setError, data, setData },
    func,
    args
) => {
    const updateDataThrowError = async (data) => {
        setLoading(true);

        try {
            const res = await func(data);
            setData(res);
            setLoading(false);
            setFirstLoad(true);
            setError(false);
            return res;
        } catch (e) {
            setError(true);
            setLoading(false);
            setFirstLoad(true);
            throw e;
        }
    };

    const updateData = async (data) => {
        setLoading(true);
        let res;
        try {
            res = await updateDataThrowError(data);
        } catch {}

        return res;
    };

    const refresh = async () => {
        if (!args) return;
        return await updateData(args);
    };

    useEffect(() => {
        if (!args) return;
        updateData(args).finally(() => setFirstLoad(true));
    }, [args, func]);

    return {
        loading,
        updateData,
        firstLoad,
        error,
        data,
        refresh,
        run: updateDataThrowError,
        updateDataThrowError,
    };
};

/**
 * Custom hook for performing asynchronous operations.
 *
 * @template T The type of the data returned by the async function.
 * @template A The argument type for the async function.
 *
 * @param {(arg: A) => Promise<T>} func - The asynchronous function to be executed.
 * @param {A| false | null | undefined} args - Arguments to be passed to the asynchronous function.
 *
 * @returns {{
 *   loading: boolean,
 *   updateData: (data: A) => Promise<T | undefined>,
 *   firstLoad: boolean,
 *   error: boolean,
 *   data: T | undefined,
 *   refresh: () => Promise<T | undefined>,
 *   run: (data: A) => Promise<T>
 * }} An object containing:
 * - `loading`: A boolean indicating if the async operation is in progress.
 * - `updateData`: A function to update data with error handling. Returns the result or undefined.
 * - `firstLoad`: A boolean indicating if the first load has completed.
 * - `error`: A boolean indicating if an error occurred.
 * - `data`: The data returned from the async operation, or undefined if not available.
 * - `refresh`: A function to refresh the data. Returns the result or undefined.
 * - `run`: A function to run the async operation and return the result.
 */
export const useAsync = (func, args) => {
    const [loading, setLoading] = useState(false);
    const [firstLoad, setFirstLoad] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();

    const baseArgs = useMemo(
        () => ({
            loading,
            setLoading,
            firstLoad,
            setFirstLoad,
            error,
            setError,
            data,
            setData,
        }),
        [loading, setLoading, firstLoad, setFirstLoad, error, setError, data, setData]
    );

    return useAsyncBase(baseArgs, func, args);
};

/**
 * Custom hook for performing asynchronous operations.
 *
 * @template T The type of the data returned by the async function.
 * @template A The argument type for the async function.
 *
 * @param {string} key - The key to use for storing the value in session storage.
 * @param {(arg: A) => Promise<T>} func - The asynchronous function to be executed.
 * @param {A| false | null | undefined} args - Arguments to be passed to the asynchronous function.
 *
 * @returns {{
 *   loading: boolean,
 *   updateData: (data: A) => Promise<T | undefined>,
 *   firstLoad: boolean,
 *   error: boolean,
 *   data: T | undefined,
 *   refresh: () => Promise<T | undefined>,
 *   run: (data: A) => Promise<T>
 * }} An object containing:
 * - `loading`: A boolean indicating if the async operation is in progress.
 * - `updateData`: A function to update data with error handling. Returns the result or undefined.
 * - `firstLoad`: A boolean indicating if the first load has completed.
 * - `error`: A boolean indicating if an error occurred.
 * - `data`: The data returned from the async operation, or undefined if not available.
 * - `refresh`: A function to refresh the data. Returns the result or undefined.
 * - `run`: A function to run the async operation and return the result.
 */
export const useAsyncSessionStorage = (key, func, args) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useSessionStorage(`${key}-error`, false);
    const [firstLoad, setFirstLoad] = useSessionStorage(`${key}-firstLoad`, false);
    const [data, setData] = useSessionStorage(`${key}-data`, undefined);

    const baseArgs = useMemo(
        () => ({
            loading,
            setLoading,
            firstLoad,
            setFirstLoad,
            error,
            setError,
            data,
            setData,
        }),
        [loading, setLoading, firstLoad, setFirstLoad, error, setError, data, setData]
    );

    return useAsyncBase(baseArgs, func, args);
};

export const useFillRemainingHeight = () => {
    const ref = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (ref.current) {
                const rect = ref.current.getBoundingClientRect();
                const maxHeight = window.innerHeight - rect.top;
                ref.current.style.maxHeight = `${maxHeight}px`;
            }
        };

        handleResize(); // Initial setup
        window.addEventListener("resize", handleResize);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }); // Empty dependency array means this effect runs once when the component mounts

    return ref;
};

const useIsomorphicLayoutEffect = useEffect;

export function useEventListener(eventName, handler, element = window, options = undefined) {
    const savedHandler = useRef(handler);

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        const targetElement = element?.current ?? window;

        if (!(targetElement && targetElement.addEventListener)) return;

        const listener = (event) => savedHandler.current(event);

        targetElement.addEventListener(eventName, listener, options);

        return () => {
            targetElement.removeEventListener(eventName, listener, options);
        };
    }, [eventName, element, options]);
}

window.customEvent = new Event("local-storage");

const REACT_ONBOARDING_ROUTE = "/app/lesson-redirect";

function parseJSON(value) {
    try {
        return value === "undefined" ? undefined : JSON.parse(value ?? "");
    } catch {
        console.log("parsing error on", { value });
        return undefined;
    }
}

function useEventCallback(fn) {
    const ref = useRef(() => {
        throw new Error("Cannot call an event handler while rendering.");
    });

    useIsomorphicLayoutEffect(() => {
        ref.current = fn;
    }, [fn]);

    return useCallback((...args) => ref.current(...args), [ref]);
}

/**
 * A custom React hook for managing state in local storage.
 *
 * @template T - The type of the stored value.
 * @param {string} key - The key to use for storing the value in local storage.
 * @param {T} initialValue - The initial value to use when no value is found in local storage.
 * @returns {[T, (value: T | ((prevValue: T) => T)) => void]} An array containing the stored value and a function to set the value in local storage.
 */
export function useLocalStorage(key, initialValue) {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback(() => {
        // Prevent build error "window is undefined" but keeps working
        if (typeof window === "undefined") {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? parseJSON(item) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    }, [initialValue, key]);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState(readValue);

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = useEventCallback((value) => {
        // Prevent build error "window is undefined" but keeps working
        if (typeof window === "undefined") {
            console.warn(
                `Tried setting localStorage key “${key}” even though environment is not a client`
            );
        }

        try {
            // Allow value to be a function so we have the same API as useState
            const newValue = value instanceof Function ? value(storedValue) : value;

            // Save to local storage
            window.localStorage.setItem(key, JSON.stringify(newValue));

            // Save state
            setStoredValue(newValue);

            // We dispatch a custom event so every useLocalStorage hook are notified
            window.dispatchEvent(new Event("local-storage"));
        } catch (error) {
            console.warn(`Error setting localStorage key “${key}”:`, error);
        }
    });

    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStorageChange = useCallback(
        (event) => {
            if (event?.key && event.key !== key) {
                return;
            }
            setStoredValue(readValue());
        },
        [key, readValue]
    );

    // this only works for other documents, not the current one
    useEventListener("storage", handleStorageChange);

    // this is a custom event, triggered in writeValueToLocalStorage
    // See: useLocalStorage()
    useEventListener("local-storage", handleStorageChange);

    return [storedValue, setValue];
}

let lessonRedirected = false;

export const useOnboardingRedirect = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const finished_onboarding_workshop =
        queryParams.get("finished_onboarding_steps_after_workshop") === "true";

    const isKid = useSelector(({ auth }) => auth.userIsChild);
    const isParent = useSelector(({ auth }) => auth.userRole === "User");
    const children = useChildren();

    useEffect(() => {
        if (!isKid) return;
        if (lessonRedirected) return;
        if (location.state?.lessonRedirect) {
            lessonRedirected = true;
            return;
        }
        if (hasFinishedOnboarding()) return;
        if (isInReactOnboardingStep()) {
            if (location.pathname !== REACT_ONBOARDING_ROUTE) history.push(REACT_ONBOARDING_ROUTE);
            return;
        }

        if (finished_onboarding_workshop) {
            kidServiceInstance.updateKidsMainOnboarding(window.onboarding_steps.react).then(() => {
                if (location.pathname !== REACT_ONBOARDING_ROUTE)
                    history.push(REACT_ONBOARDING_ROUTE);
            });
        } else {
            window.location.replace(config.gameUrl);
        }
    });

    useEffect(() => {
        if (!isParent) return;
        if (!children.firstLoad || children.loading || children.error) return;

        const anyChildHasFinishedOnboarding = children.allKids.some(
            (k) => k?.parentDataOnboardingStep > 0
        );

        if (!anyChildHasFinishedOnboarding) {
            if (
                location.pathname !== "/app/parent-onboarding" &&
                location.pathname !== "/app/child-dashboard/onboarding-form" &&
                location.state?.fromParentOnboarding !== true
            ) {
                children.refresh();
                updateAuthToken();
                history.push("/app/parent-onboarding", { fromOnboardingRedirect: true });
            }

            return;
        }
    });

    return null;
};

export const useUpdateCurrentUserRole = () => {
    const dispatch = useDispatch();

    const updateCurrentUserRole = () => {
        const user = sessionServiceInstance.getLoggedUserData();
        const role = user?.roles?.[0];
        dispatch(setAuthUserRole(role));

        if (role === "User") {
            const email = user?.email;
            const firstName = user?.firstname;
            if (email) dispatch(setAuthUserEmail(email));
            if (firstName) dispatch(setAuthUserFirstName(firstName));
        }

        return { role, user };
    };

    return updateCurrentUserRole;
};

export const usePostSessionAnalytics = () => {
    const userRole = useSelector(({ auth }) => auth?.userRole);
    useEffect(() => {
        if (userRole === "User" || userRole === "Kid") {
            console.log(`User Role - ${userRole}`);
            postUserSessionAnalytic();

            let acqParam = localStorage.getItem("acq");
            if (acqParam) {
                postUserAcquisition(acqParam);
            }
        }
    }, [userRole]);
};
/**
 * Custom hook for retrieving AI lessons for a specific kid. Optionally filters lessons by a slug.
 *
 * This hook internally uses `useAsync` to fetch all lessons for the given `kidId`. If `lessonSlug` is provided,
 * the hook filters the lessons to include only those matching the slug. Additionally, it listens for updates
 * to AI job statuses and refreshes lesson data accordingly. It also handles refreshing of lessons data under
 * certain conditions, such as when new lessons are added or lessons without AI job IDs are present.
 *
 * @param {string | undefined} kidId - The ID of the kid for whom to fetch AI lessons.
 * @param {Object} [options={}] - Optional parameters.
 * @param {string|null} [options.lessonSlug=null] - The slug of the lesson to filter by. If null, all lessons for the kid are returned.
 * @param {string|null} [options.subjectId=null] - The ID of the subject to filter by. If null, all subjects are returned.
 * @param {string|null} [options.subjectName=null] - The name of the subject to filter by. If null, all subjects are returned.
 *
 */
export const useKidAiLessons = (
    kidId,
    { lessonSlug = null, subjectId = null, subjectName = null } = {}
) => {
    const asyncArgs = useDeepCompareMemo(
        () => ({
            kidId,
            subjectId,
            subjectName,
            slug: lessonSlug,
        }),
        [kidId, lessonSlug, subjectId, subjectName]
    );

    const _kidAiLessons = useAsync(aiServiceInstance.getAllKidLessons, asyncArgs);

    const kidAiLessons = useMemo(() => {
        if (!lessonSlug) return _kidAiLessons;
        if (!_kidAiLessons.data) return _kidAiLessons;

        const filtered = _kidAiLessons.data.filter((l) => l.slug === lessonSlug);
        return {
            ..._kidAiLessons,
            data: filtered,
        };
    }, [_kidAiLessons, lessonSlug]);

    const kidAiJobIds = useMemo(
        () => (kidAiLessons.data || []).map((l) => l.aiJobId).filter((j) => j),
        [kidAiLessons]
    );

    const kidAiJobFBStatuses = useListenForMultipleAiJobStatus(kidAiJobIds);

    useEffect(() => {
        if (kidAiLessons.data) {
            kidAiLessons.data.forEach((lesson) => {
                if (!lesson.aiJobId) return;
                if (!kidAiJobFBStatuses[lesson.aiJobId]) return;
                const updated = kidAiJobFBStatuses[lesson.aiJobId] !== lesson.aiModuleStatus;

                if (updated) {
                    kidAiLessons.refresh();
                }
            });
        }
    }, [kidAiJobFBStatuses, kidAiLessons]);

    useEffect(() => {
        if (!kidAiLessons.data) return;
        const lessonsWithNoJobId = kidAiLessons.data.filter((l) => !l.aiJobId);
        if (lessonsWithNoJobId.length === 0) return;

        const intervalId = setInterval(() => {
            kidAiLessons.refresh();
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kidAiLessons.data]);

    useEffect(() => {
        if (!kidAiLessons.data) return;
        if (kidAiLessons.loading) return;
        if (kidAiLessons.error) return;

        let timeoutId;

        const lessonsLength = kidAiLessons.data?.length || 0;

        if (lessonsLength === 0) {
            timeoutId = setTimeout(() => {
                kidAiLessons.refresh();
            }, 400);
        }

        return () => {
            clearTimeout(timeoutId);
        };
    }, [kidAiLessons, kidAiLessons.data, kidAiLessons.error, kidAiLessons.loading]);

    return kidAiLessons;
};

export const useAnimationEnd = (
    callback,
    { animationName = null, eventName = "animationend" } = {}
) => {
    const ref = useRef(null);

    useEffect(() => {
        const element = ref.current;
        if (!element) return;

        const handleAnimationEnd = (a) => {
            if (callback) {
                if (!animationName || a.animationName === animationName) {
                    callback(a);
                }
            }
        };

        element.addEventListener(eventName, handleAnimationEnd);

        // Cleanup
        return () => {
            element.removeEventListener(eventName, handleAnimationEnd);
        };
    }, [animationName, callback, eventName]);

    return ref;
};

export const useBackgroundImageLoaded = (imageUrl, resolveOnError = false) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const image = new Image();
        if (imageUrl) {
            image.src = imageUrl;

            const handleImageLoad = () => {
                setIsLoaded(true);
            };

            image.onload = handleImageLoad;

            // Optional: Handle load failure
            image.onerror = () => {
                console.log("Failed to load image:", imageUrl);
                if (resolveOnError) {
                    setIsLoaded(true);
                }
            };
        }

        // Cleanup
        return () => {
            image.onload = null;
            image.onerror = null;
        };
    }, [imageUrl, resolveOnError]);

    return isLoaded;
};

/**
 * Custom hook that delays setting a boolean value to true based on a specified delay.
 * @param value The boolean value to be delayed.
 * @param delay The delay time in milliseconds before setting the value to true. Default is 500ms.
 * @returns The delayed boolean value.
 */
export function useDelayedTrue(value, delay = 500) {
    const [delayedValue, setDelayedValue] = useState(value);

    useEffect(() => {
        let timer;

        if (value) {
            // Set a timer to update the state after the specified delay
            timer = setTimeout(() => {
                setDelayedValue(value);
            }, delay);
        } else {
            // If the input value is false, update immediately
            setDelayedValue(value);
        }

        return () => {
            // Clear the timer on unmount or when value changes
            clearTimeout(timer);
        };
    }, [value, delay]);

    return delayedValue;
}

/**
 * Custom hook that delays setting a boolean value to false based on a specified delay.
 * @param value The boolean value to be delayed.
 * @param delay The delay time in milliseconds before setting the value to false. Default is 500ms.
 * @returns The delayed boolean value.
 */
export function useDelayedFalse(value, delay = 500) {
    const [delayedValue, setDelayedValue] = useState(value);

    useEffect(() => {
        let timer;

        if (!value) {
            // Set a timer to update the state after the specified delay
            timer = setTimeout(() => {
                setDelayedValue(value);
            }, delay);
        } else {
            // If the input value is true, update immediately
            setDelayedValue(value);
        }

        return () => {
            // Clear the timer on unmount or when value changes
            clearTimeout(timer);
        };
    }, [value, delay]);

    return delayedValue;
}

/**
 * Base hook for observing DOM mutations and updating state accordingly.
 *
 * @param {function} stateUpdateCallback - Function to update state based on observed mutations.
 * @returns {React.RefObject} - A ref to the DOM element being observed.
 */
function useMutationObserver(stateUpdateCallback) {
    const elementRef = useRef(null);

    useEffect(() => {
        if (!stateUpdateCallback || typeof stateUpdateCallback !== "function") {
            console.error("stateUpdateCallback must be a function.");
            return;
        }

        // Initialize state based on the current element
        if (elementRef.current) {
            stateUpdateCallback(elementRef.current);
        }

        // Set up a MutationObserver
        const observer = new MutationObserver(() => {
            if (elementRef.current) {
                stateUpdateCallback(elementRef.current);
            }
        });

        if (elementRef.current) {
            observer.observe(elementRef.current, {
                attributes: true,
                childList: true,
                subtree: true,
            });
        }

        // Clean up the observer
        return () => observer.disconnect();
    }, [stateUpdateCallback]);

    return elementRef;
}

/**
 * Custom React hook that creates and returns a ref to a dialog element,
 * along with the current open state of the dialog.
 *
 * @returns {[React.RefObject, boolean]} - A ref to the dialog element and its open state.
 */
export function useDialogState() {
    const [isOpen, setIsOpen] = useState(false);

    const updateDialogState = (element) => {
        setIsOpen(element.hasAttribute("open"));
    };

    const dialogRef = useMutationObserver(updateDialogState);

    return [dialogRef, isOpen];
}

/**
 * Custom React hook that creates a ref to a DOM element and tracks if it's
 * set to 'display: none'.
 *
 * @returns {[React.RefObject, boolean]} - A ref to the DOM element and a boolean indicating display state.
 */
export function useDisplayNone() {
    const [isDisplayNone, setIsDisplayNone] = useState(true);

    const updateDisplayState = (element) => {
        const style = window.getComputedStyle(element);
        setIsDisplayNone(style.display === "none");
    };

    const elementRef = useMutationObserver(updateDisplayState);

    return [elementRef, isDisplayNone];
}

/**
 * A custom React hook for getting the previous value of a variable.
 *
 * @template T - The type of the value.
 * @param {T} value - The current value.
 * @returns {T} The previous value of the variable.
 */
export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ProcessAudioBlobBackend = async (audioBlob) => {
    const formData = new FormData();
    formData.append("file", audioBlob, "recording.wav");

    const response = await axios.post(`${config.backendAiUrl}/ai-audio/speech-to-text`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            accept: "application/json",
        },
    });

    return await response.data;
};

export const useSpeechToText = (
    handleAudioProcessed = () => {},
    { shouldPauseAllPlayingAudioStreams = true } = {}
) => {
    const [isListening, setIsListening] = useState(false);
    const [micStream, setMicStream] = useState(null);
    const [audioRecorder, setAudioRecorder] = useState(null);
    const audioChunksRef = useRef([]);
    const shouldCancelProcessing = useRef(false);
    const [justStartedListening, setJustStartedListening] = useState(false);

    const [listeningAudioError, setListeningAudioError] = useState(null);

    const isAudioMuted = useAudio();
    const { handleSoftMute, handleSoftUnmute } = useAudioToggle();

    const {
        data: processedAudioData,
        loading: isProcessingAudio,
        error: processingAudioError,
        run: processAudio,
    } = useAsync(ProcessAudioBlobBackend, false);

    const isActive = useMemo(
        () => isProcessingAudio || isListening,
        [isListening, isProcessingAudio]
    );

    const startRecording = () => {
        if (micStream) {
            const recorder = new MediaRecorder(micStream);
            recorder.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };
            recorder.onstop = async () => {
                const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
                if (!shouldCancelProcessing.current) {
                    const { text } = await processAudio(audioBlob);
                    handleAudioProcessed(text);
                }

                audioChunksRef.current = [];
            };
            shouldCancelProcessing.current = false;
            recorder.start();
            fetch(config.backendAiUrl);

            if (audioRecorder) {
                audioRecorder.stop();
            }

            setAudioRecorder(recorder);
        }
    };

    const stopRecording = () => {
        if (audioRecorder) {
            audioRecorder.stop();
            setAudioRecorder(null);
        }
    };

    useEffect(() => {
        if (!audioRecorder) return;
        if (!shouldPauseAllPlayingAudioStreams) return;
        if (isAudioMuted) return;

        handleSoftMute();

        return () => {
            handleSoftUnmute();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioRecorder, shouldPauseAllPlayingAudioStreams]);

    const handleStartListening = useCallback(() => {
        setListeningAudioError(null);
        setJustStartedListening(true);
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((stream) => {
                setMicStream(stream);
                setIsListening(true);
                setTimeout(() => setJustStartedListening(false), 1000);
            })
            .catch((error) => {
                setListeningAudioError(error);
                toast.error("Please allow microphone access to use this feature.");
                setJustStartedListening(false);
            });
    }, []);

    const handleStopListening = useCallback(() => {
        if (micStream) {
            micStream.getTracks().forEach((track) => track.stop());
            setMicStream(null);
            setIsListening(false);
            stopRecording();
        }
    }, [micStream, audioRecorder]);

    const handleCancelProcessing = useCallback(() => {
        shouldCancelProcessing.current = true;
        handleStopListening();
    }, [handleStopListening]);

    useEffect(() => {
        if (micStream) {
            startRecording();
        }
    }, [micStream]);

    const handleCancelProcessingRef = useRef(handleStopListening);
    handleCancelProcessingRef.current = handleCancelProcessing;

    useEffect(() => {
        return () => {
            handleCancelProcessingRef.current();
        };
    }, []);

    const handleToggleListening = useCallback(() => {
        if (isListening) {
            handleStopListening();
        } else {
            handleStartListening();
        }
    }, [isListening, handleStartListening, handleStopListening]);

    const secondsListening = useTrueSecondsCounter(isListening);
    const formattedSecondsListening = useFormattedTime(secondsListening);

    return {
        isProcessingAudio,
        processingAudioError,
        isListening,
        isActive,
        listeningAudioError,
        secondsListening,
        formattedSecondsListening,
        handleStartListening,
        handleStopListening,
        handleToggleListening,
        handleCancelProcessing,
        processedAudioData,
        justStartedListening,
    };
};

function useTrueSecondsCounter(isTrue) {
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval;

        if (isTrue) {
            // Start counting when isTrue is true
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        } else {
            // Reset the counter when isTrue becomes false
            setSeconds(0);
        }

        return () => {
            // Clear interval on unmount or when isTrue changes
            clearInterval(interval);
        };
    }, [isTrue]);

    return seconds;
}

function useFormattedTime(seconds) {
    const [formattedTime, setFormattedTime] = useState("00:00");

    useEffect(() => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;

        const formattedMinutes = minutes.toString().padStart(2, "0");
        const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

        setFormattedTime(`${formattedMinutes}:${formattedSeconds}`);
    }, [seconds]);

    return formattedTime;
}
/**
 *
 * @param {function} asyncFunc THIS FUNCTION MUST CALL A PAGINATED BACKEND ENDPOINT
 * @param {any} args
 * @param {*} options
 * @returns
 */
export const usePaginateAsync = (asyncFunc, args, { pageSize = 10, initialPage = 0 } = {}) => {
    const [page, setPage] = useState(initialPage);

    const handleNextPage = () => {
        setPage((prevPage) => {
            const maxPageIdx = Math.max(0, totalPages - 1);
            return Math.min(prevPage + 1, maxPageIdx);
        });
    };

    const handlePrevPage = () => {
        setPage((prevPage) => Math.max(prevPage - 1, 0));
    };

    const asyncArgs = useMemo(
        () => ({
            ...args,
            page,
            pageSize,
        }),
        [args, page, pageSize]
    );

    const { data, loading, error, refresh } = useAsync(asyncFunc, asyncArgs);

    const paginationData = useMemo(() => {
        if (!data?.pagination) return null;
        return data.pagination;
    }, [data]);

    const _totalPages = paginationData?.totalPages || 0;
    const totalPages = useDeepCompareMemo(() => _totalPages, [_totalPages]);
    const hasNextPage = paginationData?.hasNextPage || false;
    const totalResults = paginationData?.totalResults || 0;

    useEffect(() => {
        setPage((currPage) => {
            const newPage = Math.min(currPage, totalPages - 1);

            return Math.max(newPage, 0);
        });
    }, [totalPages]);

    return {
        data: data?.data,
        totalPages,
        hasNextPage,
        totalResults,
        loading,
        error,
        refresh,
        page,
        setPage,
        handleNextPage,
        handlePrevPage,
    };
};

export const useIsLoggedIn = () => {
    const loggedIn = useSelector(({ auth }) => auth.userRole === USER || auth.userRole === ADMIN);

    return loggedIn;
};
