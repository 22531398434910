import React, { createContext, useContext, useEffect, PropsWithChildren } from "react";
import { useAsync, useLocalStorage, usePrevious } from "./hooks";
import {
    fetchKidsAndCurrentChild,
    updateCurrentChildBackend,
} from "../services/parentServices/parentServices";
import { useSelector } from "react-redux";
import { KidBackend } from "services/parentServices/types";

const ChildrenContext = createContext<KidsContext | undefined>(undefined);

const getKidsAndCurrentChildParsedData = async () => {
    const data = await fetchKidsAndCurrentChild();
    const allKids: Kid[] = data?.allKids?.map((k) => {
        if (k.avatarIndex === undefined)
            return {
                ...k,
                avatarSprites: {},
            };

        return {
            ...k,
            avatarUrl: `/assets/profile-characters/char-face-${k.avatarIndex + 1}.png`,
            avatarSprites: {
                up_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_up_walk.png`,
                down_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_down_walk.png`,
                left_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_left_walk.png`,
                right_walk: `/assets/lesson-map/characters/${k.avatarIndex + 1}_right_walk.png`,
            },
        };
    });

    const backendCurrentKidId = data.currentKid;

    return { allKids, backendCurrentKidId };
};

export const ChildrenProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const isParent = useSelector(
        ({ auth }: { auth: { userRole: string } }) => auth.userRole === "User"
    );

    const { data, ...allKidsAndSelected } = useAsync(getKidsAndCurrentChildParsedData, isParent);

    const { allKids, backendCurrentKidId } = data || { allKids: [], backendCurrentKidId: "" };

    const [currentKidId, setCurrentKidId] = useLocalStorage<string | undefined>(
        "CURRENT_KID_ID",
        undefined
    );

    const prevRenderChildId = usePrevious<string | undefined>(currentKidId);

    useEffect(() => {
        if (!currentKidId) {
            if (backendCurrentKidId) {
                setCurrentKidId(backendCurrentKidId);
            } else {
                if (allKids?.length > 0) setCurrentKidId(allKids?.[0]?._id);
            }
        }
    }, [allKids, backendCurrentKidId, currentKidId, setCurrentKidId]);

    useEffect(() => {
        if (prevRenderChildId !== currentKidId && !allKidsAndSelected.loading) {
            allKidsAndSelected.refresh();
        } else {
            if (!allKidsAndSelected.loading) {
                if (allKids?.length > 0 && currentKidId) {
                    // check if currentKidId is still valid
                    const currentKidIdx = allKids.findIndex((k) => k?._id === currentKidId);
                    if (currentKidIdx === -1) {
                        console.log("currentKidId is not valid anymore, setting to undefined");
                        setCurrentKidId(undefined);
                    }
                }
            }
        }
    }, [allKids, allKidsAndSelected, currentKidId, prevRenderChildId, setCurrentKidId]);

    const updateCurrentChild = async (childId: string) => {
        setCurrentKidId(childId);
        const res = await updateCurrentChildBackend(childId);
        allKidsAndSelected.refresh();
        return res;
    };

    const currentKidIdx = (allKids || []).findIndex((k) => k?._id === currentKidId);

    const currentKid = (allKids || [])[currentKidIdx];

    useEffect(() => {
        allKidsAndSelected.refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isParent]);

    useEffect(() => {
        if (currentKid?.avatarSprites) {
            // preload avatar sprites
            Object.values(currentKid.avatarSprites).forEach((spriteUrl) => {
                const img = new Image();
                img.src = spriteUrl;
            });
        }
    }, [currentKid]);

    return (
        <ChildrenContext.Provider
            value={{
                allKids,
                currentKid,
                currentKidIdx,
                currentKidId,
                updateCurrentChild,
                ...allKidsAndSelected,
            }}
        >
            {children}
        </ChildrenContext.Provider>
    );
};

export const useChildren = () => {
    const context = useContext(ChildrenContext);
    if (!context) {
        throw new Error("useChildren must be used within a ChildrenProvider");
    }
    return context;
};

/**
 * Defines the avatar sprite URLs for different walking directions.
 */
interface AvatarSprites {
    /** URL for the avatar's upward walking animation. */
    up_walk: string;
    /** URL for the avatar's downward walking animation. */
    down_walk: string;
    /** URL for the avatar's leftward walking animation. */
    left_walk: string;
    /** URL for the avatar's rightward walking animation. */
    right_walk: string;
}

/**
 * Represents a kid with a defined avatar, extending the basic kid backend structure.
 */
interface KidWithAvatar extends KidBackend {
    /** URL to the kid's avatar image. */
    avatarUrl: string;
    /** Sprite URLs for the kid's avatar walking animations. */
    avatarSprites: AvatarSprites;
}

/**
 * Represents a kid without a defined avatar, extending the basic kid backend structure.
 * In this case, the avatarSprites object is empty.
 */
interface KidWithoutAvatar extends KidBackend {
    /** The avatarUrl is explicitly undefined for kids without an avatar. */
    avatarUrl?: undefined;
    /** An empty object, indicating no avatar sprites are defined. */
    avatarSprites: {};
}

/**
 * A union type representing either a kid with an avatar or without one.
 */
type Kid = KidWithAvatar | KidWithoutAvatar;

/**
 * Represents the return type of a custom hook that retrieves kid data,
 * parsed and awaited for use in the application context.
 */
type AllKidsAndSelectedResponse = ReturnType<
    typeof useAsync<Awaited<ReturnType<typeof getKidsAndCurrentChildParsedData>>, any>
>;

/**
 * Context for managing and accessing kids' data within the application.
 */
interface KidsContext extends Omit<AllKidsAndSelectedResponse, "data"> {
    /** The current selected kid's ID, if any. */
    currentKidId?: string;

    /** An array of all kids' data. */
    allKids: Kid[];
    /** The currently selected kid's data, if any. */
    currentKid?: Kid;
    /** The index of the currently selected kid in the allKids array. */
    currentKidIdx?: number;
    /** A function to update the current selected child by their ID. */
    updateCurrentChild: (childId: string) => Promise<any>;
}
